@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer utilities{
  .apply_button{
    @apply bg-orange-500 shadow-xl border-2 border-orange-500 transition-all ease-in-out hover:text-orange-500 hover:bg-white text-white text-sm px-[2rem] py-[0.5rem] rounded-full;
  }
  .add_to_cart{
    @apply border-2 border-orange-500 bg-orange-500 p-2 text-white rounded-xl hover:bg-white hover:text-orange-500 transition-all ease-in-out delay-[50ms];
  }
  .submit_button{
    @apply mt-[1rem] hover:text-orange-500 hover:bg-white border-2 border-orange-500 transition-all ease-in-out delay-75 bg-orange-500 p-2 rounded-xl text-white lg:mx-[4rem];
  }
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
}