.prompt-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter:blur(12px);
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  
  .prompt-box {
    background-color: rgb(0, 0, 0);
    border-radius: 15px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 3rem 2rem;
    color: white;
    font-weight: bold;
  }
  
  .prompt-box input {
    margin: 10px 0;
    padding: 8px;
    width: 80%;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: black;
  }
  
  .prompt-actions {
    margin-top: 10px;
  }

  .prompt-actions1{
    margin: 0 10px;
    padding: 8px 16px;
    border: none;
    background-color: #0000ff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    
  }
  .prompt-actions2{
    margin: 0 10px;
    padding: 8px 16px;
    border: none;
    background-color: #ff2f00;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    
  }