@media (max-width:700px) {
    .obj-cont{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.latest-card{
    width: 20vw;
    height: 23rem;
}

@media (max-width:1200px) {
    .latest-card{
        width: 18vw;
    }
    
}
@media (max-width:1060px) {
    .latest-card{
        width: 17vw;
    }
}
@media (max-width:1022px) {
    .filter-out{
        margin-top: 10rem;
    }
}
@media (max-width:769px) {
    .latest-card{
       height: 20rem;
    }
    
}
@media (max-width:1000px) {
    .latest-card{
        width: 40vw;
    }
    
}
@media (max-width:400px) {
    .latest-card{
        width: 47vw;
        height: 17rem;
    }
    
}