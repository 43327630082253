@media (max-width:376px ) {
  .banner{
   margin-top: 38vw;
  }
  
  
}
@media (max-width:400px ) {
  .banner2{
   margin-top: 2vw;
  }
  
  
}
@media (max-width:400px ) {
  
  
  
}
.Newarrivalbanner{
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-position: center;
 }

@media (max-width:1200px) {
  .adjust-logo{
  width: 15rem;
  }
}


.img-effect-figure{ /*New arrival*/
  scale: 1;
  overflow: hidden;
}

.img-effect{
  transition: all ease .3s;

}

.img-effect:hover{ /*New arrival*/
  scale: 1.2;
}

.mySwiper {
  position: relative;
}

.swiper-button-prev-custom,
.swiper-button-next-custom {
  transition: all 0.3s ease;
}

.swiper-button-prev-custom:hover,
.swiper-button-next-custom:hover {
  background-color: #F97316;
  color: white;
}

/* Hide default Swiper navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  display: none !important;
}

.youtube-video-container{
  height : 400px !important;
}
.frame-vid{
  height: 100% !important;
}